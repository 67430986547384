import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PagesService } from './services/pages.service';
import { CommonService } from './services/common.service';
import { fromEvent } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Injectable({providedIn: 'root'})
export class AppComponent {

  online: boolean;
  isNetworkStopped = false;
  isLoading:boolean = false;

  constructor(private router: Router, private pageService: PagesService,private commonService:CommonService) {
    this.online = window.navigator.onLine;
    this.isNetworkStopped = !this.online;
    fromEvent(window, 'online').subscribe(e => {
      console.log("App is online");
      this.online = true;
      this.isNetworkStopped = !this.online;
    });
    fromEvent(window, 'offline').subscribe(e => {
      console.log("App is offline");
      this.online = false;
      this.isNetworkStopped = !this.online;
    });

    this.pageService.checkRedirection();
  }

  ngOnInit() {
    this.commonService.isLoading.subscribe((res:boolean) => {
    this.isLoading = res
    });
  }

}
