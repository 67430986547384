import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
// import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { SECRET_KEY } from '../interface/constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  static rootUrl = environment.url;
  static cdnUrl = environment.cdnUrl;
  public sKey = SECRET_KEY;
  public isLoading = new Subject;

  constructor() { }

  static getApiUrl(url) {
    return this.rootUrl + url;
  }

  static getCdnUrl(url?: string) {
    if (url)
      return this.cdnUrl + url
    return this.cdnUrl;
  }

  encrypt (msg) {
    // random salt for derivation
    var keySize = 256;
    var salt = CryptoJS.lib.WordArray.random(16);
    // well known algorithm to generate key
    var key = CryptoJS.PBKDF2(this.sKey, salt, {
        keySize: keySize/32,
        iterations: 100
      });
    // random IV
    var iv = CryptoJS.lib.WordArray.random(128/8);      
    // specify everything explicitly
    var encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC        
    });
    // combine everything together in base64 string
    var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
    return result;
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.sKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  
  loadingFunction(data:boolean){
    this.isLoading.next(data)
  }

}
