import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from './common.service';
import { map } from 'rxjs/operators';
import { LoaderService } from '../sharedmodules/loader.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  grpUrl = environment.orgUrl;

  constructor(private httpClient: HttpClient, private loaderService: LoaderService,
    private errService: ErrorService, private router: Router) { }
    public signOutSuccessMsg: any;
    public isStudentAttendance: boolean = false;
    public fromPage: any;

  getSiteList() {
    let reqUrl = CommonService.getApiUrl('/kiosk/site/location');
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getSiteLocationInfo(siteId, locationId) {
    let reqUrl = CommonService.getApiUrl('/visitor/sitelocationinfo/' + siteId + '/' + locationId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getVisitortypeCount(siteId, locationId) {
    let reqUrl = CommonService.getApiUrl('/visitor/sitelocationinfo/count/' + siteId + '/' + locationId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getFormDetails(formId) {
    let reqUrl = CommonService.getApiUrl('/visitor/form/' + formId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getSiteParams(siteId) {
    let reqUrl = CommonService.getApiUrl('/visitor/siteParams/' + siteId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getSiteParamsRVisitor(siteId,rvId) {
    let reqUrl = CommonService.getApiUrl('/kiosk/siteParams/' + siteId + '/' + rvId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getAllHosts(data, siteId) {
    let reqUrl = CommonService.getApiUrl('/visitor/host/list/' + siteId);
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }

  submitForm(data) {
    let reqUrl = CommonService.getApiUrl('/kiosk/form/submit');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  checkout(data, siteId) {
    let reqUrl = CommonService.getApiUrl('/visitor/checkout/' + siteId);
    return this.httpClient.put(reqUrl, data).pipe(map((res: Response) => res));
  }

  getGuestToken() {
    let reqUrl = CommonService.getApiUrl('/visitor/guesttoken');
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  //Visitor type and visitor form redirection condition check. If the site has only one visitor type, 
  // it has to navigate to visitorform. If more than 1 site it has to show visitor type page to select
  checkVisitorTypeCount(siteId, locationId) {
    this.getVisitortypeCount(siteId, locationId).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.router.navigate(['/visitortype', siteId, locationId]);
      } else {
        this.errService.showResponseError(res.statusMessage);
      }
    }, err => {
      this.errService.showError(err);
    });
  }

  checkRedirection() {
    let sId = localStorage.getItem('sId');
    let lId = localStorage.getItem('lId');

    if (sId && lId) {
      this.router.navigate(['/visitortype', sId, lId]);
    } else {
      if (localStorage.getItem('auth')) {
        this.router.navigate(['/session/setup']);
      } else {
        this.router.navigate(['/session/signin']);
      }
    }
  }

  checkStudentRedirection() {
    let sId = localStorage.getItem('sId');
    let lId = localStorage.getItem('lId');

    if (sId && lId) {
      this.router.navigate(['/visitortype', sId, lId]);
    } else {
      if (localStorage.getItem('auth')) {
        this.router.navigate(['/session/setup']);
      } else {
        this.router.navigate(['/session/signin']);
      }
    }
  }
  submitBadgeData(data) {
    let reqUrl = CommonService.getApiUrl('/kiosk/badge/submit');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }
  getSignedInVisitors(data) {
    let reqUrl = CommonService.getApiUrl('/kiosk/signedin/list');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }
  visitorSignOut(data, siteId) {
    let reqUrl = CommonService.getApiUrl('/kiosk/checkout/' + siteId);
    return this.httpClient.put(reqUrl, data).pipe(map((res: Response) => res));
  }
  getFormInfo(data) {
    let reqUrl = CommonService.getApiUrl('/kiosk/signedin/status');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  //09:08:21 m
  getStudents(data) {
    let reqUrl = CommonService.getApiUrl('/student/getstudents');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
  getAbsenceType(data) {
    let reqUrl = CommonService.getApiUrl('/student/getAbsenceTypes');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
  getlatetoschoolpassreasons(data) {
    let reqUrl = CommonService.getApiUrl('/student/getlatetoschoolpassreasons');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
  getleavepassreasons(data) {
    let reqUrl = CommonService.getApiUrl('/student/getleavepassreasons');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
  // 12:08:21 m
  getincompleteleavepasses(data) {
    let reqUrl = CommonService.getApiUrl('/student/getincompleteleavepasses');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
  addlatetoschoolpass(data) {
    let reqUrl = CommonService.getApiUrl('/student/addlatetoschoolpass');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
  addleavepass(data) {
    let reqUrl = CommonService.getApiUrl('/student/addleavepass');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
  kioskFormSubmit(data) {
    let reqUrl = CommonService.getApiUrl('/kiosk/form/submit');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
  // Returning Student  get List
  kioskVisitDetails(siteID,uid) {
    let reqUrl = CommonService.getApiUrl('/kiosk/visitdetails/' +siteID + '/' + uid);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }
  kioskReturningStudent(data){
    let reqUrl = CommonService.getApiUrl('/kiosk/form/submit/returningstudent');
    return this.httpClient.put(reqUrl,data).pipe(map((res: Response) => res));
  }
  completePassSubmit(data) {
    let reqUrl = CommonService.getApiUrl('/student/completeleavepass');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }

  getAllOrganisationList(data){
    let reqUrl = this.grpUrl + '/Groups/fulllist';
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  getOrgToken(data) {
    let reqUrl = this.grpUrl + '/Groups/getOrgToken';
    const reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + localStorage.getItem('initAuth'),
      'clienttoken': data.clienttoken
    });
    return this.httpClient.get(reqUrl, { headers: reqHeader });
  }

  getStudentList(data){
    let reqUrl = CommonService.getApiUrl('/student/list');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }

  getStudentStatus(data){
    let reqUrl = CommonService.getApiUrl('/student/status');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
  
}
