import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  snackbarConfig: MatSnackBarConfig = {
    duration: 6000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
    panelClass: 'custom-snackbar'
  }

  constructor(private snackBar: MatSnackBar, private router: Router) { }

  showError(errObj) {
    if (errObj.statusCode == '400' || errObj.statusCode == 401) {
      this.snackBar.open('Session has expired. Please Login again.', null, this.snackbarConfig);
      localStorage.removeItem('auth');
      localStorage.removeItem('user');
      this.router.navigate(['/session/signin']);
    }else if (errObj.statusText == undefined || errObj.statusText == "Unknown error" || errObj.statusText == "Unknown Error") {
      this.snackBar.open('Plese check the network connection', null, this.snackbarConfig);
    }
     else {
      this.snackBar.open(errObj.statusText, ' ', this.snackbarConfig);
    }
  }

  showResponseError(msg) {
    this.snackBar.open(msg, ' ', this.snackbarConfig);
  }

  showSuccessMsg(msg) {
    this.snackBar.open(msg, ' ', this.snackbarConfig);
  }

}
