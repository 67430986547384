<div class="dflex spinner w-100 all_ctr mt-50" *ngIf="isLoading">
    <mat-spinner [diameter]="60" [color]="'primary'"></mat-spinner>
</div>
<router-outlet></router-outlet>


<div id="wifi-overlay" [ngStyle]="{'display': isNetworkStopped ? 'block' : 'none'}">
    <div id="wifi-overlay-text">
        <img width="200px" src="assets/images/wi-fi.png"><br/>
        Please connect <br/> to the internet
    </div>
</div>