
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './components/core/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/core/auth-layout/auth-layout.component';
import { AuthGuard } from './guards/auth.guard';

export const AppRoutes: Routes = [{
    path: "",
    redirectTo: "session/signin",
    pathMatch: "full"
},
{
    path: "",
    component: AdminLayoutComponent,
    children: []
},
{
    path: "",
    component: AuthLayoutComponent,
    children: [{
        path: "session",
        loadChildren: () => import("./pages/session/session.module").then(m => m.SessionModule)
    },
    {
        path: 'visitorform',
        loadChildren: () => import("./pages/visitor-form/visitor-form.module").then(m => m.VisitorFormModule),
        canActivate: [AuthGuard]
    },
    {
        path: "visitortype/:siteId/:locationId",
        loadChildren: () => import("./pages/visitor-type/visitor-type.module").then(m => m.VisitorTypeModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'visitorpass',
        loadChildren: () => import("./pages/visiter-pass/visiter-pass.module").then(m => m.VisiterPassModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'studentForm',
        loadChildren: () => import("./pages/student-attendance/student-attendance.module").then(m => m.StudentAttendanceModule),
        canActivate: [AuthGuard]
    }
    ]
}];