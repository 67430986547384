<div class="dflex all_ctr flex_dir ">
  <mat-drawer-container class="example-container" autosize [hasBackdrop]="true">

    <mat-drawer #drawer class="side_drawer" mode="over">
      <div class="logoimg dflex all_ctr mt-30 mb-20">
        <img class="mt-5 mb-30" src="assets/images/logo.png" alt="logo-img" width="89">
      </div>
    </mat-drawer>
    <div class="main_container">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
  
</div>